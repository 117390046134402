import {
  AxIcon,
  AxIconButton
} from '@common/modules/react/themes/components';

import I18n from '@common/libs/I18n';

type AxDialogCloseButtonProps = {
  qaClassName: `qa-${ string }`
  onBack?: () => void
};

export default function AxDialogCloseButton(props: AxDialogCloseButtonProps) {
  const {
    onBack,
    qaClassName
  } = props;

  return <AxIconButton
    className={ qaClassName }
    size='medium'
    aria-label={ I18n.t('general.back') }
    onClick={ onBack }
    sx={{
      borderColor: 'transparent',
      '&:hover': {
        borderColor: 'transparent'
      }
    }}
  >
    <AxIcon iconName='icon-chevron_left' />
  </AxIconButton>;
}
